import { Injectable } from '@angular/core';
import { ServerApi } from './server-api.service';
import { Platform } from '@ionic/angular';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  dataLanguage = null;
  SELECTED_LANG = 'id';
  DATA_LANGUAGE_KEY = 'dataLanguage';

  constructor(
    private serverApi: ServerApi,
    private storage: StorageService,
    private platform: Platform
  ) { 
    // this.platform.ready().then(() => {
      this.getLanguagesDictionary();
    // });
  }

  setLanguageID(idLang) {
    this.SELECTED_LANG = idLang;
    return this.getLanguagesDictionary();
  }

  async getLanguage() {
    if (this.dataLanguage == null) {
      this.dataLanguage = await this.getLanguagesDictionary();
    } 
    else {
      let key = this.DATA_LANGUAGE_KEY + this.SELECTED_LANG;
      return this.storage.getItem(key).then((data) => {
        if (data) {
          this.dataLanguage = data;
          return data;
        }
        else
          this.getLanguagesDictionary();
      });   
    }   
  }

  getLanguagesDictionary() {
    return this.serverApi.getLanguagesFromServer({'lang_id' : this.SELECTED_LANG}).then((res) => {
      let key = this.DATA_LANGUAGE_KEY + this.SELECTED_LANG;
      this.storage.setItem(key, res['data']).then(() => {
        console.log(this.dataLanguage);
        this.dataLanguage = res['data'];
      });
    });
  }

  translate(strLang, returnEmpty=0) {
    if (this.dataLanguage == null) 
     return '['+strLang+']';
    else {
      if (this.dataLanguage.hasOwnProperty(strLang)) {
        return this.dataLanguage[strLang];
      }
      else {
        if (returnEmpty) return '';
        else return '['+strLang+']';
      }
    }
  }
}
