import { Injectable } from '@angular/core';
import { ServerApi } from './server-api.service';
import { LanguageService } from './language.service'
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController } from '@ionic/angular';
import { UserData } from '../interfaces/UserData';
import { StorageService } from './storage.service';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  dataUser : UserData;

  constructor(
    private serverApi : ServerApi,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    public menuCtrl: MenuController,
    private router : Router,
    private lang: LanguageService,
    private storage: StorageService,
    private iab : InAppBrowser,

  ) { }

  checkIsLogin(bolRedirect) {
    return this.storage.getItem(this.serverApi.DATA_USER_KEY).then(
      async (value) => {
        if (value) {
            this.dataUser = JSON.parse(value);;
            await this.serverApi.updateFcmToken(this.dataUser.id);

            this.menuCtrl.enable(true, "adminMenu");
            if (!bolRedirect) {
              this.router.navigateByUrl('/dashboard');
            } 
            else {
              if (this.lang.SELECTED_LANG != this.dataUser.lang)
                await this.lang.setLanguageID(this.dataUser.lang);  
              else {
                this.lang.SELECTED_LANG = this.dataUser.lang;
                await this.lang.getLanguage();
              }
            }
        }
    }, err => {
      console.log('error get data user', err);
      this.menuCtrl.enable(false, "adminMenu");

      if(bolRedirect) this.router.navigateByUrl('/');
      else this.lang.getLanguage();
    });
  }

  async showAlertNotif(errorMsg, headerText) {
    const alert = await this.alertCtrl.create({
      header: headerText,
      message: errorMsg,
      buttons: ['OK']
    });

    return await alert.present();
  }   

  async changeLanguage(langID) {
    const loadingElement = await this.loadingCtrl.create({
      message: 'Please Wait',
      spinner: 'crescent',
      // duration: 2000
    });

    return await loadingElement.present().then(async () => {
      this.lang.setLanguageID(langID).then(async () => {
        this.loadingCtrl.dismiss();
      });
    });
  }    

  async logOutUser() {
    await this.serverApi.logout().then((data) => {
      this.menuCtrl.enable(false, "adminMenu");
      this.router.navigateByUrl('/');
    });
  }

  async openPaymentPage(redirectUrl) {
    var browserOption: InAppBrowserOptions = {
      clearcache : 'yes',
      cleardata : 'yes',
      clearsessioncache : 'yes',
      location : 'no',
      hideurlbar : 'yes',
      toolbar : 'no',
      usewkwebview : 'yes',
      hidden: 'yes',
      transitionstyle : 'fliphorizontal'
    };
    
    const loadingElement = await this.loadingCtrl.create({
      message: 'Opening page...',
      spinner: 'crescent',
    });
    
    const browser = this.iab.create(redirectUrl, "_self", browserOption); 

    browser.on('loadstart').subscribe(async event => {
      console.log(event);
      await loadingElement.present();
    }, err => {
      this.showAlertNotif(err, 'Error Loading Page');
    }); 

    browser.on('loadstop').subscribe((event) => {
      setTimeout( () => {
        loadingElement.dismiss();
        browser.show();
      }, 2000);
    },
    err => {
      loadingElement.dismiss();
      this.showAlertNotif('Please check your internet connection', 'Error Loading Page');
    });

    browser.on('message').subscribe(browserParams => {
      // console.log(browserParams);

      switch(browserParams.data.action) {
        case 'redirect-dashboard' : 
          this.router.navigateByUrl('/dashboard');
          browser.close();
          break;

        default :
      }
    },
    (err) => {

    },() => {
      // console.log('complete');
    });    
  }

}
