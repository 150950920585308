import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';

@NgModule({
    declarations: [AppComponent],
    imports: [
      BrowserModule, 
      CommonModule, 
      IonicModule.forRoot(), 
      AppRoutingModule, 
      HttpClientModule,
      IonicStorageModule.forRoot({
        name: '__psbrecisdb',
        driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
      })
    ],
    providers: [
        HttpClient,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        InAppBrowser,
        FCM,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
