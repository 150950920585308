import { Component } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { EventsService } from './service/events.service';
import { LanguageService } from './service/language.service';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { ServerApi } from './service/server-api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public userPages = [
    {
      title: 'dashboard',
      url: '/dashboard',
      icon: 'browsers'
    },
    {
      title: 'submission_form',
      url: '/form-submission',
      icon: 'clipboard'
    },
    {
      title: 'profile',
      url: '/profile',
      icon: 'person'
    }
  ];  

  constructor(
    public lang : LanguageService,
    public eventService: EventsService,
    public menuCtrl: MenuController,
    public fcm : FCM,
    private platform: Platform,
    public serverApi: ServerApi,
  ) {
    this.menuCtrl.enable(false, "adminMenu");
    this.platform.ready().then(() => {
      // this.fcm.subscribeToTopic('evbike');
      this.fcm.getToken().then(token => {
        this.serverApi.fcm_tokey_key = token
      });
      
      this.fcm.onNotification().subscribe(data => {
        if(data.wasTapped){
          console.log("Received in background")
        } else {
          console.log("Received in foreground")
        };
      });
      
      this.fcm.onTokenRefresh().subscribe(token => {
        this.serverApi.fcm_tokey_key = token
      });
      
      this.fcm.hasPermission().then(hasPermission => {
        if (hasPermission) {
          console.log("Has permission!")
        }
      })
    });
  }
}
